import isNil from 'lodash/isNil';
import isFinite from 'lodash/isFinite';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import getDynamicQuestionnaire from './getDynamicQuestionnaire';

const renderer = new marked.Renderer();

/**
 * Replaces all Markdown statements (including html tags) with plain text.
 * @param {string} text
 * @returns {string}
 */
function defaultRenderMarkdown(text) {
  const textWithHtml = marked.parseInline(text, {
    renderer,
  });
  return sanitizeHtml(textWithHtml, {
    allowedTags: [], // empty to strip all tags away
  });
}

/**
 * @typedef {object} PresentableAnswer
 * @property {string} value
 * @property {string} label
 * @property {string} questionType
 * @property {string} questionId
 * @property {Record<string, unknown>} answer
 */

/**
 * @param {import('../models/Questionnaire').default} questionnaire
 * @param {import('../models/EvaluationScope').default} evaluationScope
 * @param {object} [options]
 * @param {boolean} [options.includeMissing=false]
 * @param {(question: import('../models/Question').default) => boolean} [options.filterQuestions]
 * @param {boolean} [options.preserveNumbers=false]
 * @param {boolean} [options.forInternalUse=true]
 * @param {(markdown: string) => string} [options.renderMarkdown]
 * @returns {PresentableAnswer[]}
 */
function presentAnswersFromEvaluationScope(
  questionnaire,
  evaluationScope,
  options = {},
) {
  const {
    includeMissing = false,
    filterQuestions = null,
    preserveNumbers = false,
    // NOTE: This flag is to make sure what formatted responses include as much information as possible e.g. values, labels, etc..
    // It's enabled by default because users of the provider portal usually need responses that include lots of data
    // But it's useful to disable it for external interfaces such as the patient facing app
    // See https://theclinician.atlassian.net/browse/TECH-1114?focusedCommentId=23860
    forInternalUse = true,
    renderMarkdown = defaultRenderMarkdown,
  } = options;
  const { formValues, properties } =
    evaluateFormValuesAndProperties(evaluationScope);
  const dynamicQuestionnaire = getDynamicQuestionnaire(
    questionnaire,
    properties,
  );
  const results = [];
  dynamicQuestionnaire.forEachQuestion(
    (question) => {
      const answer = formValues[question.id];
      const formattedAnswer =
        preserveNumbers && answer && isFinite(answer.value)
          ? answer.value
          : question.formatAnswer(answer, renderMarkdown, forInternalUse);
      // NOTE: In this case, "nil" means either empty answer or
      //       problematic question type, e.g. collection
      if (includeMissing || !isNil(formattedAnswer)) {
        results.push({
          label: renderMarkdown(question.getTitle()),
          // NOTE: We are not running answer through htmlToPlainText here
          //       so if the value comes from the patient input, the consumer
          //       of results will need to ensure that it's sanitized
          //       appropriately.
          value: formattedAnswer,
          answer,
          questionType: question.type,
          questionId: question.id,
        });
      }
    },
    {
      stopRecursion: (question) => question.isCollection(),
      filterQuestions: (question) => {
        if (!question.hasPresentableValue()) {
          return false;
        }
        if (typeof filterQuestions !== 'function') {
          return true;
        }
        return !!filterQuestions(question);
      },
    },
  );
  return results;
}

export default presentAnswersFromEvaluationScope;
