import map from 'lodash/map';
import isPlainObject from 'lodash/isPlainObject';
import checkSchema from '@zedoc/check-schema';
import {
  estimateTheta,
  getParamsFromConfig,
  getStandardError,
} from '@zedoc/cat-irt';
import Formula from '../Formula';
import { FORMULA_TYPE__IRT_CALCULATION } from '../../../constants';
import { isEmptyAnswer } from '../../../utils/question';

const settingsSchema = {
  type: 'object',
  required: ['property', 'config', 'operands'],
  properties: {
    property: {
      enum: ['theta', 'standardError'],
    },
    config: {
      type: 'object',
    },
    operands: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id'],
        properties: {
          id: {
            type: 'string',
          },
        },
      },
    },
  },
};

class FormulaIRTCalculation extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (checkSchema(settingsSchema, this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const config = Formula.create(this.settings.config).evaluate(scope);
    if (config.error) {
      return {
        error: config.error,
      };
    }
    if (!isPlainObject(config.value)) {
      return {
        error: this.constructor.NoData,
      };
    }
    const params = getParamsFromConfig(config.value);
    const responses = this.settings.operands.flatMap(({ id }) => {
      const answer = scope.lookupAnswer(id);
      if (!isEmptyAnswer(answer)) {
        return [
          {
            questionId: id,
            answer,
          },
        ];
      }
      return [];
    });
    let value;
    switch (this.settings.property) {
      case 'theta': {
        value = estimateTheta(params, responses);
        break;
      }
      case 'standardError': {
        const theta = estimateTheta(params, responses);
        value = getStandardError(params, responses, theta);
        break;
      }
      default: {
        // ...
      }
    }
    if (Number.isFinite(value)) {
      return {
        value,
      };
    }
    return {
      error: this.constructor.NoData,
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        property: this.settings.property,
      },
    };
    if (this.meta && this.meta.sectionId) {
      compiled.settings.operands = questionsHierarchy.mapQuestions(
        (q) => ({
          id: q.id,
        }),
        {
          sectionId: this.meta.sectionId,
        },
      );
      const question = questionsHierarchy.getQuestionById(this.meta.sectionId);
      if (question && question.settings && question.settings.irtConfigFormula) {
        compiled.settings.config = Formula.create(
          question.settings.irtConfigFormula,
        ).compile(questionsHierarchy);
      }
    }
    return compiled;
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'config':
          return Formula.create(value).remap(mapQuestionId).toRawFormula();
        case 'operands':
          return map(value, (operand) => ({
            ...operand,
            id: mapQuestionId(operand.id),
          }));
        default:
          return value;
      }
    };
  }

  static createMapMeta(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'sectionId':
          return mapQuestionId(value);
        case 'questionIds':
          return map(value, mapQuestionId);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__IRT_CALCULATION] = FormulaIRTCalculation;

export default FormulaIRTCalculation;
