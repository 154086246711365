// @ts-check
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import { toSerializableValue } from '@zedoc/form-values';
import { RESPONSE_SOURCE__ELEMENTS_ORDER } from '../constants';

/**
 * @typedef {null | number | number[] | boolean | string | string[]} AnswerValue
 */

/**
 * @typedef {object} Answer
 * @property {AnswerValue} [value]
 * @property {AnswerValue} [other]
 * @property {string} [text1]
 * @property {string} [text2]
 * @property {{}} [error]
 */

const ANSWER_PROPERTIES = /** @type {const} */ ([
  'value',
  'other',
  'text1',
  'text2',
  'error',
]);

/**
 * A type representing a single element of a form values object.
 * @typedef {object} Element
 * @property {AnswerValue} [value]
 * @property {AnswerValue} [other]
 * @property {string} [text1]
 * @property {string} [text2]
 * @property {{}} [error]
 * @property {import('../constants').NullAnswer} [whyEmpty]
 * @property {string} [source]
 * @property {number} [editedTs]
 * @property {number} [sequenceNo]
 * @property {string[]} [_elementsOrder]
 * @property {Record<string, import('@zedoc/form-values').ValueDescriptor<Element>>} [_elements]
 */

/**
 * @typedef {(
 *   | 'array'
 * )} ResponseType
 */

/**
 * @typedef {object} Response
 * @property {Answer} answer
 * @property {string} questionId
 * @property {string} [hierarchyKey]
 * @property {number} [editedTs]
 * @property {import('../constants').NullAnswer} [whyEmpty]
 * @property {import('../constants').ResponseSource} [source]
 */

/**
 * @typedef {object} ResponseAnnotations
 * @property {number} [sequenceNo]
 */

/**
 * @typedef {Response & ResponseAnnotations} ResponseWithAnnotations
 */

const RESPONSE_PROPERTIES = /** @type {const} */ ([
  'whyEmpty',
  'source',
  'editedTs',
]);

// NOTE: This is important to make sure that this ID is different from any real question IDs.
const PLACEHOLDER_QUESTION_ID = '$';

/**
 * Returns a unique key for the given response.
 * @param {Response} response
 * @returns {string}
 */
export const getResponseKey = (response) =>
  response.hierarchyKey
    ? `${response.hierarchyKey}.${response.questionId}`
    : response.questionId;

/**
 * @template T
 * @param {T} x
 * @returns {() => T}
 */
const constant = (x) => () => x;

/**
 * @param {Element} element
 * @returns {Answer}
 */
export function toAnswer(element) {
  if (element._elementsOrder) {
    return {
      value: element._elementsOrder,
    };
  }
  return pick(element, ANSWER_PROPERTIES);
}

/**
 * @param {Element} element
 * @param {string} questionId
 * @param {string} [hierarchyKey]
 * @returns {ResponseWithAnnotations}
 */
export function toResponseWithAnnotations(element, questionId, hierarchyKey) {
  /** @type {ResponseWithAnnotations} */
  const response = {
    questionId,
    answer: toAnswer(element),
  };
  if (element._elementsOrder) {
    response.source = RESPONSE_SOURCE__ELEMENTS_ORDER;
  }
  Object.assign(response, pick(element, RESPONSE_PROPERTIES));
  if (hierarchyKey !== undefined) {
    response.hierarchyKey = hierarchyKey;
  }
  if (element.sequenceNo !== undefined) {
    response.sequenceNo = element.sequenceNo;
  }
  return response;
}

/**
 * @param {Response} response
 * @returns {Response}
 */
export function cleanResponse(response) {
  const { questionId, hierarchyKey, answer } = response;
  return {
    questionId,
    answer: pick(answer, ANSWER_PROPERTIES),
    hierarchyKey,
    ...pick(response, RESPONSE_PROPERTIES),
  };
}

/**
 * @typedef {object} SimpleElement
 * @property {AnswerValue} [value]
 * @property {{}} [error]
 */

/**
 * @param {import('@zedoc/form-values').FormValues<Element>} formValues
 * @returns {import('@zedoc/form-values').FormValues<SimpleElement>}
 */
export function simplifyFormValues(formValues) {
  return mapValues(formValues, (element) => {
    if (element._elements || element._elementsOrder) {
      return {
        _elements: element._elements
          ? simplifyFormValues(element._elements)
          : {},
        _elementsOrder: element._elementsOrder,
      };
    }
    return pick(element, ['value', 'error']);
  });
}

/**
 * @param {Element} element
 * @param {string} questionId
 * @param {string} [hierarchyKey]
 * @returns {Response}
 */
export function toResponse(element, questionId, hierarchyKey) {
  return cleanResponse(
    toResponseWithAnnotations(element, questionId, hierarchyKey),
  );
}

/**
 * @param {import('@zedoc/form-values').FormValues<Element> | undefined} elements
 * @returns {Record<string, ResponseWithAnnotations>}
 */
function elementsToResponsesMap(elements) {
  /** @type {Record<string, ResponseWithAnnotations>} */
  const responsesMap = {};
  /**
   * @param {Element} element
   * @param {string} elementId
   * @returns {Record<string, Response>}
   */
  const assign = (element, elementId) => {
    if (!element) {
      return responsesMap;
    }
    if (element._elements || element._elementsOrder) {
      forEach(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        toResponsesMap(element._elements),
        (response, key) => {
          responsesMap[`${elementId}.${key}`] = {
            ...response,
            hierarchyKey: response.hierarchyKey
              ? `${elementId}.${response.hierarchyKey}`
              : `${elementId}`,
          };
        },
      );
    } else {
      responsesMap[elementId] = toResponseWithAnnotations(
        element,
        PLACEHOLDER_QUESTION_ID,
        elementId,
      );
    }
    return responsesMap;
  };

  forEach(elements, (element, id) => {
    if (element) {
      assign(element, id);
    }
  });

  return responsesMap;
}

/**
 * @param {import('@zedoc/form-values').FormValues<Element> | undefined} formValues
 * @returns {Record<string, ResponseWithAnnotations>}
 */
export function toResponsesMap(formValues) {
  /** @type {Record<string, ResponseWithAnnotations>} */
  const responsesMap = {};
  /**
   * @param {Element} element
   * @param {string} questionId
   * @returns {Record<string, Response>}
   */
  const assign = (element, questionId) => {
    if (element._elements || element._elementsOrder) {
      // NOTE: This is a collection question clearly.
      forEach(elementsToResponsesMap(element._elements), (response, key) => {
        responsesMap[`${questionId}.${key}`] = {
          ...response,
          hierarchyKey: response.hierarchyKey
            ? `${questionId}.${response.hierarchyKey}`
            : `${questionId}`,
        };
      });
      if (element._elementsOrder) {
        responsesMap[questionId] = toResponseWithAnnotations(
          element,
          questionId,
        );
      }
    } else {
      responsesMap[questionId] = toResponseWithAnnotations(element, questionId);
    }
    return responsesMap;
  };

  forEach(formValues, (element, id) => {
    if (element) {
      assign(element, id);
    }
  });

  return responsesMap;
}

/**
 * Evaluate responses "diff" based on current and original form values.
 * @param {import('@zedoc/form-values').FormValues<Element>} newFormValues
 * @param {import('@zedoc/form-values').FormValues<Element>} oldFormValues
 * @return {Response[]}
 */
export function toResponsesArray(newFormValues, oldFormValues = {}) {
  const newResponsesMap = toResponsesMap(newFormValues);
  const oldResponsesMap = toResponsesMap(oldFormValues);
  /** @type {ResponseWithAnnotations[]} */
  const newResponses = [];
  forEach(newResponsesMap, (newResponse, key) => {
    const oldResponse = oldResponsesMap[key];
    if (!oldResponse) {
      newResponses.push(newResponse);
      return;
    }
    /** @type {Answer | undefined} */
    const oldAnswer = oldResponse.answer;
    const oldMetadata = pick(oldResponse, RESPONSE_PROPERTIES);
    const newAnswer = newResponse.answer;
    const newMetadata = pick(newResponse, RESPONSE_PROPERTIES);
    if (!isEqual(oldAnswer, newAnswer) || !isEqual(oldMetadata, newMetadata)) {
      newResponses.push(newResponse);
    }
  });
  return orderBy(newResponses, ['sequenceNo', 'editedTs']).map(cleanResponse);
}

/**
 * @param {unknown} value
 * @returns {value is string[]}
 */
function isArrayOfStrings(value) {
  return isArray(value) && value.every(isString);
}

/**
 * @param {Response} response
 * @param {number} sequenceNo
 * @returns {Element}
 */
const toElement = (response, sequenceNo) => {
  /** @type {Element} */
  const element = {
    sequenceNo,
  };
  if (response.source === RESPONSE_SOURCE__ELEMENTS_ORDER) {
    if (isArrayOfStrings(response.answer.value)) {
      element._elementsOrder = response.answer.value;
    } else {
      element._elementsOrder = [];
    }
    return element;
  }
  Object.assign(element, pick(response.answer, ANSWER_PROPERTIES));
  Object.assign(element, pick(response, RESPONSE_PROPERTIES));
  return element;
};

/**
 * Convert responses array to a hierarchical form values object that
 * can be used with redux-form to represent form state.
 * @param {Response[]} responses
 * @returns {import('@zedoc/form-values').FormValues<Element>}
 */
export function toFormValues(responses) {
  /** @type {import('@zedoc/form-values').ValueDescriptor<Element>} */
  const formValues = {
    _elements: {},
  };
  /**
   * @param {import('@zedoc/form-values').ValueDescriptor<Element>} object
   * @param {string[]} parts
   * @param {Element} element
   * @returns {import('@zedoc/form-values').ValueDescriptor<Element>}
   */
  const assign = (object = {}, parts, element) => {
    if (parts.length === 0) {
      Object.assign(object, element);
    } else {
      if (!object._elements) {
        // eslint-disable-next-line no-param-reassign
        object._elements = {};
      }
      // eslint-disable-next-line no-param-reassign
      object._elements[parts[0]] = assign(
        object._elements[parts[0]],
        parts.slice(1),
        element,
      );
    }
    return object;
  };

  forEach(responses, (response, index) => {
    if (!response || !response.answer) {
      return;
    }
    const parts = response.hierarchyKey ? response.hierarchyKey.split('.') : [];
    if (response.questionId !== PLACEHOLDER_QUESTION_ID) {
      parts.push(response.questionId);
    }
    assign(formValues, parts, toElement(response, index));
  });

  if (!formValues._elements) {
    throw new Error('Impossible!');
  }
  return formValues._elements;
}

/**
 * @param {Response[]} responses
 * @returns {import('@zedoc/form-values').SerializableObject}
 */
export function toFormValuesCollapsed(responses) {
  const formValues = toFormValues(responses);
  return /** @type {import('@zedoc/form-values').SerializableObject} */ (
    toSerializableValue({
      _elements: formValues,
    })
  );
}

/**
 * @param {Response[]} responses
 * @returns {import('@zedoc/form-values').FormValues<SimpleElement>}
 */
export function toSimpleFormValues(responses) {
  return simplifyFormValues(toFormValues(responses));
}

/**
 * @template {Response} T
 * @param {T[]} responses
 * @param {Object} [options]
 * @param {(response: T) => boolean} [options.predicate]
 * @returns {T[]}
 */
export function filterResponses(
  responses,
  { predicate = constant(true) } = {},
) {
  const acceptableResponses = responses.filter(
    (response) => response && response.questionId,
  );
  const responsesByKey = keyBy(acceptableResponses, getResponseKey);
  /** @type {Record<string, boolean>} */
  const validResponses = {};
  /**
   * @param {T} response
   * @returns {T | undefined}
   */
  const getParentResponse = (response) => {
    if (!response.hierarchyKey) {
      return undefined;
    }
    const parts = response.hierarchyKey.split('.');
    while (parts.length > 0) {
      const hierarchyKey = parts.join('.');
      if (responsesByKey[hierarchyKey]) {
        return responsesByKey[hierarchyKey];
      }
      parts.pop();
    }
    return undefined;
  };
  /**
   * @param {T} response
   * @returns {boolean}
   */
  const isValidResponse = (response) => {
    const key = getResponseKey(response);
    if (validResponses[key] !== undefined) {
      return validResponses[key];
    }
    if (!response.hierarchyKey) {
      validResponses[key] = predicate(response);
    } else {
      const parentResponse = getParentResponse(response);
      if (!parentResponse) {
        validResponses[key] = false;
      } else {
        // NOTE: By the definition of getParentResponse, parentKey
        //       is at most equal to response.hierarchyKey, and
        //       because the latter is always shorter than getResponseKey(response)
        //       there's no risk of infinite recursion.
        const parentKey = getResponseKey(parentResponse);
        if (parentKey === response.hierarchyKey) {
          // NOTE: We call isValidResponse() recursively here!
          validResponses[key] = !!(
            predicate(response) && isValidResponse(parentResponse)
          );
        } else {
          const elementId = response.hierarchyKey.substring(
            parentKey.length + 1,
          );
          validResponses[key] = !!(
            predicate(response) &&
            // NOTE: We call isValidResponse() recursively here!
            isValidResponse(parentResponse) &&
            parentResponse.answer &&
            isArrayOfStrings(parentResponse.answer.value) &&
            parentResponse.answer.value.indexOf(elementId) >= 0
          );
        }
      }
    }
    return validResponses[key];
  };
  /** @type {T[]} */
  const filteredAndTransformedResponses = [];
  forEach(acceptableResponses, (response) => {
    if (isValidResponse(response)) {
      filteredAndTransformedResponses.push(response);
    }
  });
  return filteredAndTransformedResponses;
}
