// Mike: Make it a 64k char limit. Pen testers can think it's for fancy reasons :)
export const ANSWER_VALUE_MAX_LENGTH = 65536;

export const RESPONSE_SOURCE__USER_INPUT = 'USER_INPUT';
export const RESPONSE_SOURCE__INITIAL_BINDING = 'INITIAL_BINDING';
export const RESPONSE_SOURCE__FORMULA = 'FORMULA';
export const RESPONSE_SOURCE__ELEMENTS_ORDER = 'ELEMENTS_ORDER';
export const RESPONSE_SOURCES = /** @type {const} */ ([
  RESPONSE_SOURCE__USER_INPUT,
  RESPONSE_SOURCE__INITIAL_BINDING,
  RESPONSE_SOURCE__FORMULA,
  RESPONSE_SOURCE__ELEMENTS_ORDER,
]);

/**
 * @typedef {import('./types').Unwrap<typeof RESPONSE_SOURCES>} ResponseSource
 */

export const NULL_ANSWER__NO_INFORMATION = 'NO_INFORMATION';
export const NULL_ANSWER__SKIPPED = 'SKIPPED';
export const NULL_ANSWER__UNKNOWN = 'UNKNOWN';
export const NULL_ANSWER__MASKED = 'MASKED';
export const NULL_ANSWER__NOT_APPLICABLE = 'NA';
export const NULL_ANSWERS = /** @type {const} */ ([
  NULL_ANSWER__NO_INFORMATION,
  NULL_ANSWER__SKIPPED,
  NULL_ANSWER__UNKNOWN,
  NULL_ANSWER__MASKED,
  NULL_ANSWER__NOT_APPLICABLE,
]);

/**
 * @typedef {import('./types').Unwrap<typeof NULL_ANSWERS>} NullAnswer
 */

export const NULL_ANSWER_OPTIONS = [
  {
    value: NULL_ANSWER__NO_INFORMATION,
    label: 'No information',
  },
  {
    value: NULL_ANSWER__SKIPPED,
    label: 'Skipped',
  },
  {
    value: NULL_ANSWER__UNKNOWN,
    label: 'Unknown',
  },
  {
    value: NULL_ANSWER__MASKED,
    label: 'Masked',
  },
  {
    value: NULL_ANSWER__NOT_APPLICABLE,
    label: 'Not applicable',
  },
];

export const QUESTION_CHUNK__OTHER_FIELD = 'otherField';
export const QUESTION_CHUNK__TEXT_FIELD_1 = 'textField1';
export const QUESTION_CHUNK__TEXT_FIELD_2 = 'textField2';

export const QUESTION_CHUNK_FIELDS = [
  {
    id: QUESTION_CHUNK__OTHER_FIELD,
    name: 'other',
  },
  {
    id: QUESTION_CHUNK__TEXT_FIELD_1,
    name: 'text1',
  },
  {
    id: QUESTION_CHUNK__TEXT_FIELD_2,
    name: 'text2',
  },
];
